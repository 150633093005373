import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { Container } from "../components/utils"
import NewsList from "../components/newsList"
import FeaturedNews from "../components/featuredNews"
import Pagination from "react-js-pagination"
import DesignTokens from "../components/designTokens"
import { getNewsPath } from "../utils/path"
import { LanguageContext } from "../context/langContext"

const NewsListPage = ({ data: { page, site }, pageContext }) => {
  const locale = pageContext.locale
  const localeSuffix = locale === "it" ? "" : "/en"
  const featured = page.nodes.slice(0, 1)
  const others = page.nodes.slice(1)
  const getLink = pageNumber => {
    if (pageNumber === 1 || pageNumber === 0) {
      return `${localeSuffix}/news/`
    } else {
      return `${localeSuffix}/news/${pageNumber}/`
    }
  }
  const handleChange = pageNumber => {
    if (pageNumber === 1) {
      navigate(`${localeSuffix}/news/`)
    } else {
      navigate(`${localeSuffix}/news/${pageNumber}/`)
    }
  }
  const i18nPaths =
    pageContext.currentPage === 1
      ? site.locales.map(locale => {
          return {
            locale: locale,
            value: getNewsPath(locale),
          }
        })
      : []

  return (
    <Layout locale={locale} i18nPaths={i18nPaths}>
      <LanguageContext.Consumer>
        {t => (
          <SEO title={`${t.news} - ${pageContext.currentPage}`} lang={locale} />
        )}
      </LanguageContext.Consumer>
      <Header locale={locale} theme="light" />
      <CustomContainer>
        <FeaturedNews news={featured[0]} locale={locale} />
        <NewsList news={others} locale={locale} />
        <Pagination
          hideDisabled
          hideNavigation
          activePage={pageContext.currentPage}
          itemsCountPerPage={pageContext.limit}
          totalItemsCount={pageContext.numStoriesPages * pageContext.limit}
          pageRangeDisplayed={5}
          onChange={handleChange}
          getPageUrl={getLink}
        />
      </CustomContainer>
    </Layout>
  )
}

export default NewsListPage

const CustomContainer = styled(Container)`
  .pagination {
    margin: 0 0 3rem;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > li {
      margin: 0 1rem;
      &.active {
        a {
          text-decoration: none;
          color: ${DesignTokens.colors.black};
          cursor: auto;
        }
      }
    }
  }
`

export const query = graphql`
  query NewsListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: allDatoCmsNews(
      filter: { locale: { eq: $locale }, slug: { ne: null } }
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        id
        title
        slug
        originalPublishedAt
        _allSlugLocales {
          value
          locale
        }
        meta {
          firstPublishedAt
        }
        heroImage {
          sizes(imgixParams: { fit: "crop", w: "350", h: "260" }) {
            base64
            width
            height
            src
            srcSet
            sizes
          }
          alt
          url
        }
      }
    }
  }
`
